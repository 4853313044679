*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.bottom-0 {
  bottom: 0px
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.top-0 {
  top: 0px
}

.-z-10 {
  z-index: -10
}

.z-10 {
  z-index: 10
}

.z-\[1000\] {
  z-index: 1000
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-\[72px\] {
  margin-top: 72px;
  margin-bottom: 72px
}

.-mt-\[20px\] {
  margin-top: -20px
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-\[-50px\] {
  margin-bottom: -50px
}

.mb-\[16px\] {
  margin-bottom: 16px
}

.mb-\[40px\] {
  margin-bottom: 40px
}

.mb-\[8px\] {
  margin-bottom: 8px
}

.ml-10 {
  margin-left: 2.5rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mr-\[10px\] {
  margin-right: 10px
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-\[12px\] {
  margin-top: 12px
}

.mt-\[16px\] {
  margin-top: 16px
}

.mt-\[30px\] {
  margin-top: 30px
}

.mt-\[56px\] {
  margin-top: 56px
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.contents {
  display: contents
}

.h-\[100px\] {
  height: 100px
}

.h-\[300px\] {
  height: 300px
}

.h-\[34px\] {
  height: 34px
}

.h-\[41px\] {
  height: 41px
}

.h-\[600px\] {
  height: 600px
}

.h-\[64px\] {
  height: 64px
}

.h-\[82px\] {
  height: 82px
}

.h-full {
  height: 100%
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.w-4 {
  width: 1rem
}

.w-\[100px\] {
  width: 100px
}

.w-\[190px\] {
  width: 190px
}

.w-\[200px\] {
  width: 200px
}

.w-\[34px\] {
  width: 34px
}

.w-\[64px\] {
  width: 64px
}

.w-\[82px\] {
  width: 82px
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.min-w-\[286px\] {
  min-width: 286px
}

.min-w-\[360px\] {
  min-width: 360px
}

.max-w-\[1440px\] {
  max-width: 1440px
}

.max-w-\[340px\] {
  max-width: 340px
}

.max-w-\[350px\] {
  max-width: 350px
}

.max-w-\[400px\] {
  max-width: 400px
}

.max-w-\[460px\] {
  max-width: 460px
}

.max-w-\[650px\] {
  max-width: 650px
}

.flex-1 {
  flex: 1 1 0%
}

.flex-shrink-0 {
  flex-shrink: 0
}

.basis-1\/3 {
  flex-basis: 33.333333%
}

.border-collapse {
  border-collapse: collapse
}

.origin-\[0\] {
  -webkit-transform-origin: 0;
          transform-origin: 0
}

.-translate-y-\[8px\] {
  --tw-translate-y: -8px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.resize {
  resize: both
}

.list-inside {
  list-style-position: inside
}

.list-decimal {
  list-style-type: decimal
}

.list-disc {
  list-style-type: disc
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 0.5rem
}

.gap-\[10px\] {
  gap: 10px
}

.gap-\[16px\] {
  gap: 16px
}

.gap-\[24px\] {
  gap: 24px
}

.gap-\[30px\] {
  gap: 30px
}

.gap-\[60px\] {
  gap: 60px
}

.gap-\[8px\] {
  gap: 8px
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-break-spaces {
  white-space: break-spaces
}

.rounded-\[12px\] {
  border-radius: 12px
}

.rounded-\[16px\] {
  border-radius: 16px
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-b {
  border-bottom-width: 1px
}

.border-b-\[1px\] {
  border-bottom-width: 1px
}

.border-metalic-silver {
  border-color: var(--metalic-silver)
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity))
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity))
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity))
}

.border-b-metalic-silver {
  border-bottom-color: var(--metalic-silver)
}

.bg-anti-flash {
  background-color: var(--anti-flash)
}

.bg-silver-100 {
  background-color: var(--silver-100)
}

.bg-silver-200 {
  background-color: var(--silver-200)
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.stroke-white {
  stroke: #fff
}

.object-cover {
  object-fit: cover
}

.p-2 {
  padding: 0.5rem
}

.p-\[24px\] {
  padding: 24px
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px
}

.py-\[32px\] {
  padding-top: 32px;
  padding-bottom: 32px
}

.py-\[56px\] {
  padding-top: 56px;
  padding-bottom: 56px
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pb-\[10px\] {
  padding-bottom: 10px
}

.pb-\[16px\] {
  padding-bottom: 16px
}

.pb-\[20px\] {
  padding-bottom: 20px
}

.pb-\[24px\] {
  padding-bottom: 24px
}

.pb-\[30px\] {
  padding-bottom: 30px
}

.pb-\[40px\] {
  padding-bottom: 40px
}

.pb-\[60px\] {
  padding-bottom: 60px
}

.pb-\[70px\] {
  padding-bottom: 70px
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-1 {
  padding-top: 0.25rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pt-\[100\%\] {
  padding-top: 100%
}

.pt-\[12px\] {
  padding-top: 12px
}

.pt-\[140px\] {
  padding-top: 140px
}

.pt-\[16px\] {
  padding-top: 16px
}

.pt-\[20px\] {
  padding-top: 20px
}

.pt-\[24px\] {
  padding-top: 24px
}

.pt-\[32px\] {
  padding-top: 32px
}

.pt-\[40px\] {
  padding-top: 40px
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-\[16px\] {
  font-size: 16px
}

.text-\[18px\] {
  font-size: 18px
}

.text-\[20px\] {
  font-size: 20px
}

.text-\[22px\] {
  font-size: 22px
}

.text-\[24px\] {
  font-size: 24px
}

.text-\[28px\] {
  font-size: 28px
}

.text-\[32px\] {
  font-size: 32px
}

.text-\[38px\] {
  font-size: 38px
}

.text-\[40px\] {
  font-size: 40px
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.leading-\[1\.25\] {
  line-height: 1.25
}

.leading-\[1\.2\] {
  line-height: 1.2
}

.leading-\[1\.4\] {
  line-height: 1.4
}

.leading-\[1\.5\] {
  line-height: 1.5
}

.text-danger {
  color: var(--danger-color)
}

.text-metalic-silver {
  color: var(--metalic-silver)
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.text-secondary-color {
  color: var(--secondary-color)
}

.text-support-success {
  color: var(--support-success)
}

.text-tertiary {
  color: var(--tertiary)
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
  outline-style: solid
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-300 {
  transition-duration: 300ms
}

.first\:pt-0:first-child {
  padding-top: 0px
}

.last\:text-center:last-child {
  text-align: center
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:translate-y-\[16px\] {
  --tw-translate-y: 16px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-\[18px\] {
  font-size: 18px
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-6 {
  line-height: 1.5rem
}

.peer:focus ~ .peer-focus\:bottom-0 {
  bottom: 0px
}

.peer:focus ~ .peer-focus\:-translate-y-\[8px\] {
  --tw-translate-y: -8px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.peer:focus ~ .peer-focus\:text-\[14px\] {
  font-size: 14px
}

.peer:focus ~ .peer-focus\:leading-\[20px\] {
  line-height: 20px
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:gap-4 {
    gap: 1rem
  }

  .md\:pb-\[56px\] {
    padding-bottom: 56px
  }
}

@media (min-width: 1024px) {
  .lg\:mb-\[16px\] {
    margin-bottom: 16px
  }

  .lg\:min-w-\[464px\] {
    min-width: 464px
  }

  .lg\:flex-row {
    flex-direction: row
  }
}

@media (min-width: 1280px) {
  .xl\:w-10\/12 {
    width: 83.333333%
  }

  .xl\:max-w-\[560px\] {
    max-width: 560px
  }

  .xl\:max-w-\[590px\] {
    max-width: 590px
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:gap-\[160px\] {
    gap: 160px
  }
}

.\[\&\>div\]\:mx-auto>div {
  margin-left: auto;
  margin-right: auto
}

.\[\&\>div\]\:max-w-\[1440px\]>div {
  max-width: 1440px
}

.\[\&\>div\]\:px-\[15px\]>div {
  padding-left: 15px;
  padding-right: 15px
}

.\[\&\>div\]\:pt-\[75\%\]>div {
  padding-top: 75%
}

@media (min-width: 1280px) {
  .xl\:\[\&\>div\]\:pt-\[128\%\]>div {
    padding-top: 128%
  }
}

.\[\&\>svg\]\:h-\[60px\]>svg {
  height: 60px
}

.\[\&\>svg\]\:w-\[60px\]>svg {
  width: 60px
}

.\[\&_\.mt-button\]\:h-\[56px\] .mt-button {
  height: 56px
}

.\[\&_\.mt-button\]\:px-\[20px\] .mt-button {
  padding-left: 20px;
  padding-right: 20px
}

.\[\&_\.mt-button\]\:text-\[20px\] .mt-button {
  font-size: 20px
}
